<template>
    <div class="flex-column d-flex justify-content-center align-items-center" style="height: 100vh">
        <b-link class="brand-logo">
            <vuexy-logo />
        </b-link>
        <h1 class="py-1" >خطأ في الإتصال</h1>
        <h1>500</h1>
    </div>
</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'
export default {
    components: {
        VuexyLogo
    }
}
</script>